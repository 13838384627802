<template>
  <div class="pages">
    <pageHeader @search="search"></pageHeader>
    <div class="box">
      <div class="main">
        <div class="main-left">
          <div class="article-type">
            <span
              class="article-type-item"
              :class="articleIdx === index ? 'article-type-item-active' : ''"
              v-for="(item, index) in articleType"
              :key="item.id"
              @click="changeTab(index)"
              >{{ item }}</span
            >
          </div>
          <div class="huati-box" v-if="articleIdx === 1">
            <div class="follow-lists">
              <div
                class="follow-item"
                :class="isTopicSelect(item) ? 'follow-item-active' : ''"
                v-for="(item, index) in typeLists"
                :key="item.id"
                @click="chooseType(item)"
              >
                <img
                  v-if="isTopicSelect(item)"
                  class="huati-icon"
                  src="../../static//icon/huati-icon-select.png"
                />
                <img
                  v-else
                  class="huati-icon"
                  src="../../static//icon/huati-icon.png"
                />
                <span>{{ item.name }}</span>
                <img
                  @click.stop="addCollect(item.id, 1, index)"
                  v-if="!item.isCollection"
                  class="x-icon"
                  src="../../static/icon/jia-icon.png"
                />
              </div>
            </div>
          </div>
          <div class="article-lists">
            <div
              class="article-item"
              v-for="item in articleListsTop"
              :key="item.id"
              @click="goDetails(item.id)"
            >
              <img class="top-icon" src="../../static/icon/top-icon.png" />
              <div class="title-box">
                <span class="time" style="font-size: 14px">{{
                  item.creationTime.split('T')[0]
                }}</span>
                <span class="time">{{
                  item.creationTime.split('T')[1].split(':')[0] +
                  ':' +
                  item.creationTime.split('T')[1].split(':')[1]
                }}</span>
                <span class="column"
                  ><span v-for="(el, elIdx) in item.tags" :key="elIdx"
                    >#{{ el }}</span
                  ></span
                >
                <span class="title">{{ item.title }}</span>
              </div>
              <span class="content">{{ item.description }}</span>
              <div class="footer-box">
                <span class="num">阅读 {{ item.pv }} · 评论 {{ item.cn }}</span>
                <div class="is-look">
                  <img
                    v-if="isUnlock"
                    class="unlock-icon"
                    src="../../static/icon/suo-icon.png"
                  />
                  <span v-if="isUnlock" class="unlock">解锁后阅读</span>
                  <span v-if="!isUnlock" class="go-details">查看详情</span>
                </div>
              </div>
            </div>
            <div
              class="article-item"
              v-for="item in articleLists"
              :key="item.id"
              @click="goDetails(item.id)"
            >
              <div class="title-box">
                <span class="time" style="font-size: 14px">{{
                  item.creationTime.split('T')[0]
                }}</span>
                <span class="time">{{
                  item.creationTime.split('T')[1].split(':')[0] +
                  ':' +
                  item.creationTime.split('T')[1].split(':')[1]
                }}</span>
                <span class="column"
                  ><span v-for="(el, elIdx) in item.tags" :key="elIdx"
                    >#{{ el }}</span
                  ></span
                >
                <span class="title">{{ item.title }}</span>
              </div>
              <span class="content">{{ item.description }}</span>
              <div class="footer-box">
                <span class="num">阅读 {{ item.pv }} · 评论 {{ item.cn }}</span>
                <div class="is-look">
                  <img
                    v-if="isUnlock"
                    class="unlock-icon"
                    src="../../static/icon/suo-icon.png"
                  />
                  <span v-if="isUnlock" class="unlock">解锁后阅读</span>
                  <span v-if="!isUnlock" class="go-details">查看详情</span>
                </div>
              </div>
            </div>
            <getMore @getMore="getMore" :state="getMoreState"></getMore>
          </div>
        </div>
        <div class="main-right">
          <homeRight
            @addHuati="addHuati"
            @loginOpen="isShowLogin = true"
          ></homeRight>
        </div>
      </div>
    </div>
    <pageFooter></pageFooter>
    <loginBox :isShow="isShowLogin" @close="isShowLogin = false"></loginBox>
    <bindBox :show="isShowBindBox" @close="isShowBindBox = false" :bindCode="bindCode"></bindBox>
  </div>
</template>

<script>
import loginBox from '@/components/loginBox.vue'
import bindBox from '@/components/bindBox.vue'
import pageHeader from '@/components/pageHeader.vue'
import pageFooter from '@/components/pageFooter.vue'
import homeRight from '@/components/homeRight.vue'
import getMore from '@/components/getMore.vue'
import { getTopArticle, getHomeArticle } from '@/api/article.js'
import { gethotTopLists } from '@/api/homeRight.js'
import { addCollect } from '@/api/collect.js'
export default {
  name: 'Home',
  components: {
    pageHeader,
    pageFooter,
    homeRight,
    getMore,
    loginBox,
    bindBox
  },
  data() {
    return {
      articleType: ['推荐', '关注'], //文章类型
      articleIdx: 0,
      articleLists: [], //主文章列表
      articleListsTop: [], //置顶文章列表
      isUnlock: false,
      page: 0,
      pageSize: 10,
      total: null,
      typeLists: [],
      chooseTypeLists: [], //选择的话题
      title: '', //搜索词
      getMoreState: 'getMore',
      isShowLogin: false, //是否展示登录框
      bindCode:'',//绑定码
      isShowBindBox:false,//绑定手机号弹窗是否展示
    }
  },
  created() {
    if (this.$route.query.title) {
      this.title = this.$route.query.title
    }
    if (this.$route.query.huati) {
      this.addHuati(this.$route.query.huati)
    }
    // console.log(this.$store.state.bindCode)
    // if(this.$store.state.bindCode && !this.getToken()){
    //   this.bindCode = this.$store.state.bindCode
    if(this.$route.query.bindCode && !this.getToken()){
      this.bindCode = this.$route.query.bindCode
      this.isShowBindBox = true
    }
    this.getTopArticle()
  },
  methods: {
    /**
     * 添加话题关注
     */
    addCollect(id, type, index) {
      addCollect({
        ids: [id],
        type
      }).then(() => {
        this.$message.success('关注成功')
        this.typeLists[index].isCollection = true
      })
    },
    /**
     * 搜索
     */
    search(e) {
      this.title = e
      this.articleLists = []
      this.page = 0
      this.total = null
      this.getTopArticle()
    },
    /**
     * 加载更多
     */
    getMore() {
      this.getMoreState = 'loading'
      setTimeout(() => {
        this.getHomeArticle()
      }, 800)
    },
    /**
     * 查看详情
     */
    goDetails(id) {
      this.$router.push({ path: '/details', query: { id: id } })
    },
    /**
     * 获取置顶文章
     */
    getTopArticle() {
      getTopArticle({
        top: 1000
      }).then((res) => {
        this.articleListsTop = res
        this.getHomeArticle()
      })
    },
    /**
     * 获取首页文章列表
     */
    getHomeArticle() {
      if (this.total === this.articleLists.length) {
        this.getMoreState = 'noMore'
        return
      }
      this.page++
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        filter: {
          top: false,
          title: this.title
        }
      }
      if (this.articleIdx === 1) {
        let tagNames = []
        for (let i in this.chooseTypeLists) {
          tagNames.push(this.chooseTypeLists[i].name)
        }
        data.filter.tagNames = tagNames
      }
      getHomeArticle(data).then((res) => {
        this.articleLists = this.articleLists.concat(res.items)
        this.total = res.totalCount
        if (res.items.length < this.pageSize) {
          this.getMoreState = 'noMore'
        } else {
          this.getMoreState = 'getMore'
        }
      })
    },
    /**
     * 切换展示
     */
    changeTab(index) {
      this.articleIdx = index
      if (index === 1) {
        this.gethotTopLists()
      } else {
        this.articleLists = []
        this.page = 0
        this.total = null
        this.getTopArticle()
      }
    },
    /**
     * 获取顶部标签列表
     */
    gethotTopLists() {
      gethotTopLists({
        top: 1000
      }).then((res) => {
        this.typeLists = res
        this.articleLists = []
        this.page = 0
        this.total = null
        this.getHomeArticle()
      })
    },

    /**
     * 判断是否选中话题
     * @param item
     */
    isTopicSelect(item) {
      return this.chooseTypeLists.some(
        (el) => el.id === item.id && el.name === item.name
      )
    },

    /**
     * 选择顶部话题
     */
    chooseType(item) {
      if (this.isTopicSelect(item)) {
        //移除选中
        this.chooseTypeLists = this.chooseTypeLists.filter(
          (el) => el.id !== item.id && el.name !== item.name
        )
      } else {
        this.chooseTypeLists.push(item)
      }
      this.articleLists = []
      this.page = 0
      this.total = null
      console.log(this.chooseTypeLists)

      this.getHomeArticle()
    },
    addHuati(item) {
      this.articleIdx = 1
      this.chooseTypeLists = []
      this.chooseTypeLists.push(item)
      this.articleLists = []
      this.page = 0
      this.total = null
      this.gethotTopLists()
      console.log(this.chooseTypeLists)
    }
  }
}
</script>

<style scoped>
.pages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: #f7f7f8;
}

.main {
  width: 1200px;
  min-height: calc(100vh - 120px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.main-left {
  width: 888px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.main-left .article-type {
  background-color: #ffffff;
  width: 888px;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.main-left .article-type-item {
  width: 78px;
  height: 32px;
  background: #f3f4f7;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #424d57;
  margin-right: 16px;
  cursor: pointer;
}

.main-left .article-type-item-active {
  color: #ffffff;
  background: #dd0722;
}

.huati-box {
  background-color: #ffffff;
  width: 888px;
  padding-bottom: 24px;
}

.follow-lists {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 0 20px;
}

.follow-item {
  padding: 10px 14px;
  border-radius: 22px;
  background-color: #f3f4f7;
  margin-right: 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.follow-item .huati-icon {
  width: 26px;
  height: 26px;
}

.follow-item span {
  color: #06121e;
  font-size: 16px;
  margin: 0 6px;
}

.follow-item-active span {
  color: #4181b8;
}

.follow-item .x-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.article-lists {
  width: 888px;
  background-color: #ffffff;
}

.article-item {
  width: 848px;
  padding: 20px 0;
  margin: 0 20px;
  border-bottom: 1px solid #cdd2d9;
  cursor: pointer;
  position: relative;
}
.top-icon {
  position: absolute;
  top: 0;
  right: -20px;
  width: 44px;
}

.article-item:last-child {
  border: none;
}

.article-item .title-box {
  display: flex;
  align-items: center;
}

.article-item .title-box span {
  font-size: 18px;
  margin-right: 8px;
  font-weight: bold;
}

.article-item .title-box .time {
  color: #e2450a;
}

.article-item .title-box .column {
  color: #4181b8;
}

.article-item .title-box .title {
  color: #06121e;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.article-item .content {
  display: block;
  margin-top: 16px;
  font-size: 16px;
  color: #06121e;
}

.article-item .footer-box {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.article-item .footer-box .num {
  font-size: 12px;
  color: #bcbcbc;
}

.article-item .footer-box .is-look {
  display: flex;
  align-items: center;
}

.article-item .footer-box .is-look .go-details {
  color: #bcbcbc;
  font-size: 12px;
}

.article-item .footer-box .is-look .unlock-icon {
  margin-right: 6px;
  width: 12px;
  height: 12px;
}

.article-item .footer-box .is-look .unlock {
  color: #e2450a;
  font-size: 12px;
}

.main-right {
  width: 296px;
}
</style>
